import _ from 'lodash';
import { useFocusTrap } from '@vueuse/integrations';
import { Ref, watch } from '@vue/composition-api';

export interface IUseControlledFocusTrapProps {
  enable: Ref<boolean>
  target: Parameters<typeof useFocusTrap>[0]
  options?: Parameters<typeof useFocusTrap>[1]
}

export const useControlledFocusTrap = ({
  enable,
  target,
  options,
}: IUseControlledFocusTrapProps) => {
  let activate = _.noop;
  let deactivate = _.noop;

  watch(enable, (val) => {
    deactivate();

    if (val) {
      const instance = useFocusTrap(target, options);

      activate = instance.activate;
      deactivate = instance.deactivate;

      activate();
    } else {
      activate = _.noop;
      deactivate = _.noop;
    }
  }, { immediate: true });
};
