/* eslint-disable camelcase */
import { defineRequest } from '@/api/helpers/request';

export interface IFetchListProps {
  language: string;
  filters: Record<string, string[]>;
  page: number;
}

export interface IResponseFetchList {
  data: {
    id: number;
    language: string;
    title: string;
    description: string;
    event_datetime: {
      to: string;
      from: string;
      timezone: string;
    }[];
    location: null | string;
    status: string;
    status_label: string;
    event_page_link?: string | null;
    image: {
      name: string;
      description: string;
      thumbnail: string;
      '1x': string;
      '2x': string;
    } | null;
    event_video?: {
      open_modal: boolean;
      link: string;
    };
  }[];
  links: {
    first: string | null;
    last: string | null;
    prev: string | null;
    next: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

export const fetchList = defineRequest(({
  request,
}) => async ({
  language,
  filters,
  page,
}: IFetchListProps): Promise<IResponseFetchList> => {
  const { data } = await request.post<IResponseFetchList>('/event', {
    page: page ?? 1,
    language,
    ...filters,
  });
  return data;
});
