










































































import { defineComponent, watch } from '@vue/composition-api';
import { useVModel, templateRef } from '@vueuse/core';
import { useLockBodyScroll } from '@/services/lock-body-scroll';
import { useControlledKeyStroke } from '@/services/controlled-key-stroke';
import { useControlledClickOutside } from '@/services/controlled-click-outside';
import { useControlledFocusTrap } from '@/services/controlled-focus-trap';

export default defineComponent({
  props: {
    src: {
      type: String,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    let prevFocusElement: HTMLElement | null = null;
    const pVisible = useVModel(props, 'visible', ctx.emit);
    const content = templateRef<HTMLElement | null>('content', null);

    useLockBodyScroll({
      enable: pVisible,
      target: content,
    });

    useControlledKeyStroke({
      enable: pVisible,
      key: 'Escape',
      handler: (e) => {
        e.preventDefault();
        pVisible.value = false;
      },
    });

    useControlledClickOutside({
      enable: pVisible,
      target: content,
      handler: () => {
        pVisible.value = false;
      },
    });

    useControlledFocusTrap({
      enable: pVisible,
      target: content,
    });

    watch(pVisible, (val) => {
      if (val) {
        prevFocusElement = (document.activeElement as HTMLElement);
      } else {
        // eslint-disable-next-line no-unused-expressions
        prevFocusElement?.focus();
        prevFocusElement = null;
      }
    }, { immediate: true });

    return {
      pVisible,
    };
  },
});
