





























































































































































/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
import { computed, defineComponent } from '@vue/composition-api';
import EventCardDateRange from '@/components/EventCardDateRange.vue';
import VideoPlayIcon from '@/assets/icons/video-play.svg?inline';
import type { PropType } from '@vue/composition-api';
import type { IEventCardItem } from '@/services/event-card-list';

export interface IProps {
  data: IEventCardItem
}

const IMAGE_SET_KEYS = [
  '1x',
  '2x',
];

export default defineComponent({
  name: 'EventCardItem',

  props: {
    data: {
      type: Object as PropType<IProps['data']>,
      required: true,
    },
  },

  emits: [
    'click-video',
  ],

  setup(props, ctx) {
    const isComingSoon = computed(() => props.data.status === 'coming_soon');
    const isFindOutMore = computed(() => props.data.status === 'find_out_more');
    const isRegisterNow = computed(() => props.data.status === 'register_now');
    const isRegisterClosed = computed(() => props.data.status === 'registration_closed');
    const isEnded = computed(() => props.data.status === 'event_has_ended');
    const isExistPrimaryLink = computed(() => typeof props.data.link === 'string');
    const isModalVideo = computed(() => props.data.video?.modal === true);
    const isExistVideoLink = computed(() => typeof props.data.video?.link === 'string');

    const visibleLocationField = computed(() => props.data.location !== null);

    const imageSrcSet = computed(() => (props.data.images
      ? Object.keys(props.data.images).map((key) => {
        if (!IMAGE_SET_KEYS.includes(key)) return;

        const src = (props.data.images as any)[key];
        if (!src) return;

        return `${src} ${key}`;
      }).filter((item) => !!item)
        .join(', ')

      : null));

    const imageSrc = computed(() => (props.data.images
      ? props.data.images['1x']
      : null));

    return {
      isComingSoon,
      isFindOutMore,
      isRegisterNow,
      isRegisterClosed,
      isEnded,
      isExistPrimaryLink,
      isModalVideo,
      isExistVideoLink,
      visibleLocationField,
      imageSrc,
      imageSrcSet,
    };
  },

  components: {
    EventCardDateRange,
    VideoPlayIcon,
  },
});
