






































































import {
  computed, defineComponent, ref, set, toRefs, watch,
} from '@vue/composition-api';
import { useVModel } from '@vueuse/core';
import FilterSection from '@/components/FilterSection.vue';
import ChevronLeftIcon from '@/assets/icons/chevron-left.svg?inline';
import type { PropType } from '@vue/composition-api';
import type { IProps as IFilterSectionProps } from '@/components/FilterSection.vue';

export interface IProps {
  data: {
    key: string
    name: string
    data: IFilterSectionProps['data'][]
  }[] | null

  value: string[]

  loading: boolean
}

export default defineComponent({
  name: 'FilterList',

  props: {
    value: {
      type: Array as PropType<IProps['value']>,
      required: true,
    },
    data: {
      type: Array as PropType<IProps['data']>,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click-back'],

  setup(props, ctx) {
    const { data } = toRefs(props);
    const pValue = useVModel(props, 'value', ctx.emit);

    const moreVisible = ref<Record<string, boolean>>({});

    const isOneMoreVisible = computed(() => {
      // eslint-disable-next-line no-underscore-dangle
      const _moreVisible = moreVisible.value;
      return Object.keys(_moreVisible)
        .some((key) => moreVisible.value[key] === true);
    });

    const handleCollapseAllClick = () => {
      const nextValue = !isOneMoreVisible.value;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in moreVisible.value) {
        if (Object.prototype.hasOwnProperty.call(moreVisible.value, key)) {
          moreVisible.value[key] = nextValue;
        }
      }
    };

    // eslint-disable-next-line no-unused-expressions
    watch(data!, (_data) => {
      if (!_data) return;
      _data.forEach((filter) => {
        set(moreVisible.value, filter.key, true);
      });
    }, { immediate: true });

    return {
      pValue,
      moreVisible,
      isOneMoreVisible,
      handleCollapseAllClick,
    };
  },

  components: {
    FilterSection,
    ChevronLeftIcon,
  },
});
