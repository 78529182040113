/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import { computed, unref } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import { fetchList } from '@/api/event-filter/list';
import { useFetch } from '@/api/helpers/fetch';
import type { Ref } from '@vue/composition-api';

export interface IUseEventFilterListProps {
  language: Ref<string>
  isChina: boolean
}

export const useEventFilterList = ({
  language,
  isChina,
}: IUseEventFilterListProps) => {
  const {
    isFetching,
    exec,
    data: internalData,
  } = useFetch(fetchList);

  const data = computed(() => {
    const _internalData = unref(internalData);
    if (!_internalData) return null;

    return _internalData.map((item) => ({
      ...item,
      data: item.data.map((item2) => ({
        ...item2,
        value: `${item.key}.${item2.value}`,
        children: item2.children
          ? item2.children.map((item3) => ({
            ...item3,
            value: `${item.key}.${item3.value}`,
          }))
          : undefined,
      })),
    })).filter((item) => {
      if (isChina && item.key === 'target_market') return false;
      return true;
    });
  });

  const labels = computed(() => {
    const _internalData = unref(internalData);
    if (!_internalData) return null;

    return _internalData.reduce((map, item) => item.data.reduce((map2, item2) => ({
      ...map2,
      [`${item.key}.${item2.value}`]: item2.label,
      ...(item2.children?.reduce((obj, item3) => ({
        ...obj,
        [`${item.key}.${item3.value}`]: item3.label,
      }), {} as Record<string, string>) ?? {}),
    }), map), {} as Record<string, string>);
  });

  debouncedWatch([
    language,
  ], ([
    _language,
  ]) => {
    exec({
      language: _language,
    });
  }, {
    immediate: true,
    debounce: 50,
  });

  return {
    isFetching,
    data,
    labels,
  };
};
