
import { toRefs, defineComponent } from '@vue/composition-api';
import { useCheckboxGroup, useCheckboxState } from '@/services/checkbox-group';

export default defineComponent({
  name: 'CheckboxGroup',

  props: {
    value: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit, slots }) {
    const { value, data } = toRefs(props);
    const state = useCheckboxState({
      value,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data,
      onChangeValue: (val) => emit('input', val),
    });
    const {
      checked,
      indeterminate,
      items,
      handleClick,
      handleItemChange,
    } = useCheckboxGroup(state);

    return () => slots.default!({
      checked: checked.value,
      indeterminate: indeterminate.value,
      items: items.value,
      handleClick,
      handleItemChange,
    });
  },
});
