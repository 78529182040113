import { AxiosInstance } from 'axios';

export interface RequestFactoryProps {
  request: AxiosInstance;
}

export interface RequestFactoryFn<TData, TArgs extends any[]> {
  (props: RequestFactoryProps): (...args: TArgs) => Promise<TData>;
}

export const defineRequest = <TData, TArgs extends any[]>(
  factory: RequestFactoryFn<TData, TArgs>,
) => factory;

export type ResolveRequestFnData<T extends RequestFactoryFn<any, any>> =
  T extends RequestFactoryFn<infer R, any>
    ? R
    : never
