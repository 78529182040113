/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-underscore-dangle */
import _ from 'lodash';
import { computed, nextTick, unref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import { useRoute } from './route';
import { useRouter } from './router';
import { getNextQuery } from '../utils/query';

interface ReactiveRouteOptions {
  /**
   * Mode to update the router query, ref is also acceptable
   *
   * @default 'replace'
   */
   mode?: Ref<'replace' | 'push'> | ('replace' | 'push');

   /**
    * Route instance, use `useRoute()` if not given
    */
   route?: ReturnType<typeof useRoute>;

   /**
    * Router instance, use `useRouter()` if not given
    */
   router?: ReturnType<typeof useRouter>;
}

export function useRouteQuery(
  name: string
): Ref<null | string | string[] | object | object[]>
export function useRouteQuery<
  T extends null | string | string[] | object | object[]
  = null | string | string[] | object | object[]
>(
  name: string,
  defaultValue?: T,
  options?: ReactiveRouteOptions
): Ref<T>
export function useRouteQuery<T extends string | string[] | object | object[]>(
  name: string,
  defaultValue?: T,
  {
    mode = 'replace',
    route = useRoute(),
    router = useRouter(),
  }: ReactiveRouteOptions = {},
) {
  return computed<any>({
    get() {
      const data = unref(route).query[name];
      if (data == null) { return defaultValue ?? null; }
      if (Array.isArray(data)) { return data.filter(Boolean); }
      return data;
    },
    set(v) {
      nextTick(() => {
        const _router = unref(router);
        const _route = unref(route);
        const _mode = unref(mode);

        _router[_mode]({
          query: getNextQuery(_route, { [name]: v }),
        }).catch(_.noop);
      });
    },
  });
}
