/* eslint-disable @typescript-eslint/no-use-before-define */
import type { ComponentOptions } from 'vue';
import type { Route, Location } from 'vue-router';

/**
 * 建立空頁
 * @param name 頁面名稱
 */
export const createEmptyPageComponent = (
  name: string,
): ComponentOptions<any> => ({
  name,
  render(h) {
    return h('router-view');
  },
});

export const tranRouteToLocation = (route: Route): Location => ({
  ...route,
  name: route.name as string,
});
