/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import { computed, unref, ref } from '@vue/composition-api';
import { useRouteQuery } from '@/router/helpers/query';
import type { WritableComputedRef, Ref } from '@vue/composition-api';
import { isPageString } from './util';

export interface IUseListPageWithRouteProps {
  queryName: string;
  defaultSize?: number | null;
}

export interface IUseListPage {
  value: WritableComputedRef<number>;
  size: Ref<number | null>;
}

export const useListPageWithRoute = ({
  queryName,
  defaultSize = 10,
}: IUseListPageWithRouteProps): IUseListPage => {
  const query = useRouteQuery<string>(queryName, '1', {
    mode: 'push',
  });

  const value = computed({
    get: () => {
      const _query = unref(query);
      if (!isPageString(_query)) return 1;
      return +_query;
    },
    set: (val) => {
      const _query = unref(query);
      // eslint-disable-next-line eqeqeq
      if (isPageString(val) && _query != val) {
        query.value = `${val}`;
      }
    },
  });

  const size = ref<number | null>(defaultSize);

  return {
    value,
    size,
  };
};
