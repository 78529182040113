/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-named-default */
import { getNextQuery } from '@/router/utils/query';
import { tranRouteToLocation } from '@/router/utils/utils';
import type { ComponentOptions, default as Vue } from 'vue';
import type { NavigationGuard } from 'vue-router';
import { isPageString } from './util';

export interface ICreateListPageMixinProps {
  queryName: string;
}

export const createListPageMixin = ({
  queryName,
}: ICreateListPageMixinProps): ComponentOptions<Vue> => {
  const guard: NavigationGuard = (to, from, next) => {
    const value = to.query[queryName];
    if (isPageString(value)) return next();

    const nextQuery = getNextQuery(to, { page: 1 });
    next({ ...tranRouteToLocation(to), query: nextQuery });
  };
  return {
    beforeRouteEnter(to, from, next) {
      guard(to, from, next);
    },
    beforeRouteUpdate(to, from, next) {
      guard(to, from, next);
    },
  };
};
