<template>
  <section
    class="lang-dialog"
    :class="{
      'lang-dialog--active': visible
    }"
  >
    <div
      class="lang-dialog-wrapper"
      :class="{
        'lang-dialog-wrapper--active': visible,
      }"
      @click.self="handleDialogClose"
    >
      <div
        class="lang-dialog__list-wrapper"
        role="dialog"
        :aria-expanded="visible"
      >
        <div class="lang-dialog__list">
          <header class="h3 lang-dialog__list__title">
            {{ config.langTitle }}
          </header>
          <div class="lang-dialog__list__category-container">
            <div
              class="lang-dialog__list__category"
              v-for="(cateItem, cateName) in config.langCateNameTable"
              :key="cateName"
            >
              <div class="h4 lang-dialog__list__category__name">
                 {{ config.langCateTable[cateName] }}
              </div>
              <ul class="lang-dialog__list__category__ul">
                <li
                  class="lang-dialog__list__category__li"
                  v-for="(item, index) in chinaPolicyFilter(cateItem)"
                  :key="index"
                >
                  <a
                    class="lang-dialog__list__category__link"
                    :href="setLangUrl(config.langList[item])"
                    :title="item"
                    :dataLang="config.langList[item]"
                    rel="nofollow"
                    :tabindex="visible ? 0 : -1"
                  >
                    {{ item }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button
          class="lang-dialog__close-btn"
          name="close"
          aria-label="close"
          @click="handleDialogClose"
        >
          <div class="lang-dialog__close-btn__close"></div>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LanguageSwitcher',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(val) {
        // eslint-disable-next-line no-undef
        $('body').css('overflow', val ? 'hidden' : 'auto');
      },
    },
  },
  methods: {
    setLangUrl(langCode) {
      return window.location.href.replace(this.config.lang, langCode);
    },
    handleDialogClose() {
      this.$emit('update:visible', false);
    },
    chinaPolicyFilter(arr) {
      if (this.config.lang === 'zh-cn' || this.config.userCountry === 'cn') return arr.filter((e) => this.config.langList[e] !== 'zh-tw' && this.config.langList[e] !== 'zh-hk');
      return arr;
    },
  },
};
</script>
