/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import { fetchList } from '@/api/event-card/list';
import { useFetch } from '@/api/helpers/fetch';
import { computed, unref, watch } from '@vue/composition-api';
import { debouncedWatch } from '@vueuse/core';
import type { Ref } from '@vue/composition-api';
import type { IEventFilterData } from './event-filter/utils';

export interface IEventCardItem {
  id: string;
  images: {
    '1x': string;
    '2x': string;
  } | null;
  name: string;
  description: string;
  dates: {
    from: string,
    to: string,
    timezone: string;
  }[];
  location: string | null;
  status: 'hidden' | 'coming_soon' | 'find_out_more' | 'register_now' | 'registration_closed' | 'event_has_ended';
  link: string | null;
  video: {
    modal: boolean;
    link: string;
  } | null;
}

export interface IUseEventCardListProps {
  language: Ref<string>
  filterFormData: Ref<IEventFilterData>
  page: Ref<number>
  pageSize: Ref<number | null>
}

export const useEventCardList = ({
  language,
  filterFormData,
  page,
  pageSize,
}: IUseEventCardListProps) => {
  const {
    exec,
    data: listData,
    isFetching,
  } = useFetch(fetchList);

  const total = computed(() => {
    const _listData = unref(listData);
    if (!_listData) return null;
    return _listData.meta.total;
  });

  const data = computed<IEventCardItem[] | null>(() => {
    const _listData = unref(listData);
    if (!_listData) return null;
    return _listData.data.map((item) => ({
      id: `${item.id}`,
      images: item.image ? {
        '1x': item.image['1x'],
        '2x': item.image['2x'],
      } : null,
      name: item.title,
      description: item.description,
      dates: item.event_datetime,
      location: item.location,
      status: item.status as any,
      link: item.event_page_link ?? null,
      video: item.event_video ? {
        modal: item.event_video.open_modal,
        link: item.event_video.link,
      } : null,
    }));
  });

  watch(listData, (val) => {
    const currPage = val?.meta.current_page;
    if (typeof currPage !== 'undefined') page.value = currPage;

    const perPage = val?.meta.per_page;
    if (typeof perPage !== 'undefined') pageSize.value = perPage;
  });

  debouncedWatch([
    page, filterFormData, language,
  ], ([
    _page, _filterFormData, _language,
  ]) => {
    try {
      exec({
        page: _page as any,
        language: _language as string,
        filters: _filterFormData as any,
      });
    } catch (error) {
      listData.value = null;
    }
  }, {
    immediate: true,
    debounce: 50,
  });

  return {
    data,
    total,
    isFetching,
  };
};
