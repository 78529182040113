/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { computed, Ref, unref } from '@vue/composition-api';
import { difference as arrayDifference, intersection as arrayIntersection } from 'extra-array';

interface DataItem<T> {
  label: string;
  value: T;
}

interface CheckboxStateProps<T> {
  data: Ref<DataItem<T>[]>;
  value: Ref<T[]>;
  onChangeValue: (value: T[]) => void;
  genUniqId?: (value: T) => string;
}

type ValueIndexRecord = Record<string, string>

type DataIndexRecord = Record<string, string>

interface CheckboxItem<T> {
  checked: boolean;
  data: T;
}

// eslint-disable-next-line no-shadow
export enum CheckboxStatus {
  Checked = 'checked',
  Unchecked = 'unchecked',
  Indeterminate = 'indeterminate',
}

interface CheckboxState<T> {
  state: Ref<CheckboxStatus>
  items: Ref<CheckboxItem<DataItem<T>>[]>
  toggleAll: () => void;
  toggleItemByValue: (value: T) => void;
  toggleItemByIndex: (index: number) => void;
}

export const useCheckboxState = <T>({
  data,
  value,
  onChangeValue,
  genUniqId = (val) => `${val}`,
}: CheckboxStateProps<T>): CheckboxState<T> => {
  const mapDataCollection = computed<[DataIndexRecord, DataIndexRecord]>(() => {
    const _data = unref(data);
    const mapIndex: DataIndexRecord = {};
    const mapKey: DataIndexRecord = {};
    _data.forEach((val, index) => {
      const id = genUniqId(val.value);
      const indexKey = `${index}`;
      mapIndex[indexKey] = id;
      mapKey[id] = indexKey;
    });
    return [mapIndex, mapKey];
  });
  const mapValueCollection = computed<[ValueIndexRecord, ValueIndexRecord]>(() => {
    const _value = unref(value);
    const mapIndex: ValueIndexRecord = {};
    const mapKey: ValueIndexRecord = {};
    _value.forEach((val, index) => {
      const id = genUniqId(val);
      const indexKey = `${index}`;
      mapIndex[indexKey] = id;
      mapKey[id] = indexKey;
    });
    return [mapIndex, mapKey];
  });
  const dataIds = computed(() => Object.keys(unref(mapDataCollection)[1]));
  const valueIds = computed(() => Object.keys(unref(mapValueCollection)[1]));
  const intersection = computed(() => arrayIntersection(unref(valueIds), unref(dataIds)));
  const state: CheckboxState<T>['state'] = computed(() => {
    const _intersection = unref(intersection);
    const _dataIds = unref(dataIds);
    if (_intersection.length === _dataIds.length) return CheckboxStatus.Checked;
    if (_intersection.length === 0) return CheckboxStatus.Unchecked;
    return CheckboxStatus.Indeterminate;
  });
  const items: CheckboxState<T>['items'] = computed(() => {
    const _data = unref(data);
    const _mapDataIndex = unref(mapDataCollection)[0];
    const _mapValueIndex = unref(mapValueCollection)[0];
    return _data.map((dataItem, index) => ({
      checked: Object.values(_mapValueIndex).some((val) => _mapDataIndex[`${index}`] === val),
      data: dataItem,
    }));
  });

  const _getValueIndexByKey = (key: string): number | undefined => {
    const _mapValueKey = unref(mapValueCollection)[1];
    const indexKey = _mapValueKey[key];
    if (typeof indexKey === 'string') return +indexKey;
  };

  const _getDataIndexByKey = (key: string): number | undefined => {
    const _mapDataKey = unref(mapDataCollection)[1];
    const indexKey = _mapDataKey[key];
    if (typeof indexKey === 'string') return +indexKey;
  };

  const _getDataItemByKey = (key: string): DataItem<T> | undefined => {
    const index = _getDataIndexByKey(key);
    if (typeof index === 'number') return unref(data)[index];
  };

  const _getDataValueByKey = (key: string): DataItem<T>['value'] | undefined => {
    const dataItem = _getDataItemByKey(key);
    if (typeof dataItem !== 'undefined') return dataItem.value;
  };

  const toggleAll: CheckboxState<T>['toggleAll'] = () => {
    const _intersection = unref(intersection);
    const _value = unref(value);
    const _state = unref(state);
    const checked = _state === CheckboxStatus.Checked;
    const nextValue: T[] = [];

    if (checked) {
      const _intersectionIndexes = _intersection.map(_getValueIndexByKey);
      for (let index = 0; index < _value.length; index++) {
        if (!_intersectionIndexes.includes(index)) nextValue.push(_value[index]);
      }
    } else {
      const leftDataIds = arrayDifference(unref(dataIds), _intersection);
      const leftValues: T[] = leftDataIds.map(_getDataValueByKey).filter((val) => !!val) as T[];

      nextValue.push(..._value, ...leftValues);
    }

    onChangeValue(nextValue);
  };

  const toggleItem = (key: string) => {
    const dataItem = _getDataItemByKey(key);
    if (!dataItem) return;

    const _value = unref(value);
    const valueIndex = _getValueIndexByKey(key);
    const nextValue = _value.slice();

    if (typeof valueIndex === 'number') {
      nextValue.splice(valueIndex, 1);
    } else {
      nextValue.push(dataItem.value);
    }

    onChangeValue(nextValue);
  };
  const toggleItemByValue: CheckboxState<T>['toggleItemByValue'] = (val) => {
    toggleItem(genUniqId(val));
  };
  const toggleItemByIndex: CheckboxState<T>['toggleItemByIndex'] = (index) => {
    const indexKey = unref(mapDataCollection)[0][`${index}`];
    if (!indexKey) return;
    toggleItem(indexKey);
  };

  return {
    state,
    items,
    toggleAll,
    toggleItemByValue,
    toggleItemByIndex,
  };
};

export const useVCheckboxProps = ({
  state,
}: {
  state: Ref<CheckboxStatus>,
}) => {
  const checked = computed(() => {
    if (unref(state) === CheckboxStatus.Checked) return true;
    return false;
  });
  const indeterminate = computed(() => {
    if (unref(state) === CheckboxStatus.Indeterminate) return true;
    return false;
  });

  return {
    checked,
    indeterminate,
  };
};

export const useCheckboxGroup = <T>({
  state,
  items,
  toggleAll,
  toggleItemByIndex,
}: CheckboxState<T>) => {
  const { checked, indeterminate } = useVCheckboxProps({ state });
  const handleClick = () => toggleAll();
  const handleItemChange = (index: number) => toggleItemByIndex(index);

  return {
    items,
    checked,
    indeterminate,
    handleClick,
    handleItemChange,
  };
};
