import { defineRequest } from '@/api/helpers/request';

export interface IFetchListProps {
  language: string;
}

export type IResponseFetchList = Array<{
  key: string;
  name: string;
  data: {
    value: string;
    label: string;
    children?: {
      value: string;
      label: string;
    }[];
  }[];
}>

export const fetchList = defineRequest(({ request }) => async ({
  language,
}: IFetchListProps): Promise<IResponseFetchList> => {
  const { data } = await request.post<IResponseFetchList>('/filter', {
    language,
  });
  return data;
});
