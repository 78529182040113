





















import {
  computed, defineComponent, toRefs, unref, watchEffect,
} from '@vue/composition-api';
import { templateRef } from '@vueuse/core';

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },

    value: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    checked: {
      type: [Boolean, Array],
      default: false,
    },

    indeterminate: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: 'checked',
    event: 'change',
  },

  emits: ['change'],

  setup(props, ctx) {
    const { indeterminate } = toRefs(props);
    const $input = templateRef<HTMLInputElement>('input', null);
    const pModalValue = computed({
      get: () => props.checked,
      set: (val) => ctx.emit('change', val),
    });

    const handleClick = () => {
      $input.value.click();
    };

    watchEffect(() => {
      // eslint-disable-next-line no-underscore-dangle
      const _$input = unref($input);
      // eslint-disable-next-line no-underscore-dangle
      const _indeterminate = unref(indeterminate);

      if (!_$input) return;
      _$input.indeterminate = _indeterminate;
    }, { flush: 'post' });

    return {
      pModalValue,
      handleClick,
    };
  },
});
