import './css/checkbox.css';
import './css/tailwind.css';
import './css/language-switch.scss';
import Vue from 'vue';
import App from './App.vue';
import { createI18n } from './i18n';
import { createApi } from './api';
import { createRouter } from './router';

Vue.config.productionTip = false;

createApi({ Vue });
const { i18n } = createI18n({ Vue });
const { router } = createRouter({ Vue });

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

declare global {
  interface Window {
    SYNO_WEB: {
      languageDisplay: string;
      i18nLang: string;
      i18nMessages: Record<string, Record<string, string>>;
      languageSwitcherConfig: {
        userCountry: string | null
        langTitle: string
        langCateNameTable: Record<string, string[]>
        langCateTable: Record<string, string>
        langList: Record<string, string>
      };
      isChina: boolean
      currentYear: string;
    };
    SYNO_CONSTANTS: {
      URL_WWW_SITE: string;
    };
    SYNO_COOKIE_ELEMENT: {
      showDialog: () => void;
    };
  }
}
