/* eslint-disable import/no-duplicates */
import _ from 'lodash';
import type VueRouter from 'vue-router';
import type { Route } from 'vue-router';
import { routeTo } from './route-to';

/**
 * 取得 query
 *
 * 由傳入的 route 取深拷貝後的 query
 * @param route
 */
export const getQuery = (route: Route) => _.cloneDeep(route.query);

/**
 * 取得下一個 query
 *
 * 會將目前的 query 合併傳入的新 query
 *
 * @param route
 * @param query 新的 Query
 */
export const getNextQuery = (route: Route, query: any) => {
  const currentQuery = getQuery(route);
  return Object.assign(currentQuery, query);
};

/**
  * 更新目前路由的 query string
  */
export const updateQuery = (router: VueRouter, query: any) => {
  const nextQuery = getNextQuery(router.currentRoute, query);
  routeTo(router, { query: nextQuery });
};
