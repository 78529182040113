import VueI18n from 'vue-i18n';
import type { VueConstructor } from 'vue';

export interface ICreateI18nProps{
  Vue: VueConstructor,
}

export interface ICreateI18n {
  i18n: VueI18n;
}

export const createI18n = ({
  Vue,
}: ICreateI18nProps): ICreateI18n => {
  Vue.use(VueI18n);

  const locale = window.SYNO_WEB.i18nLang;
  const messages = { [locale]: window.SYNO_WEB.i18nMessages };

  const i18n = new VueI18n({
    locale,
    messages,
  });

  return {
    i18n,
  };
};
