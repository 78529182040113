























































































































































/* eslint-disable no-underscore-dangle */
import {
  computed, defineComponent, toRefs, unref,
} from '@vue/composition-api';
import { useVModel } from '@vueuse/core';
import { difference as arrayDifference, intersection as arrayIntersection } from 'extra-array';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import Checkbox from '@/components/Checkbox.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';
import ChevronUpIcon from '@/assets/icons/chevron-up.svg?inline';
import ChevronDownIcon from '@/assets/icons/chevron-down.svg?inline';
import type { PropType } from '@vue/composition-api';

interface IDataItem {
  label: string;
  value: string;
  children?: IDataItem[]
}

export interface IProps {
  data: IDataItem[]
}

const flattenValues = (data: IDataItem[], includeNodeHead = false): IDataItem['value'][] => data.reduce((arr, item) => {
  if (includeNodeHead) {
    arr.push(item.value);
    if (Array.isArray(item.children)) {
      arr.push(...flattenValues(item.children));
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (Array.isArray(item.children)) {
      arr.push(...flattenValues(item.children));
    } else {
      arr.push(item.value);
    }
  }
  return arr;
}, [] as IDataItem['value'][]);

export default defineComponent({
  name: 'FilterSection',

  props: {
    name: {
      type: String,
      required: true,
    },
    data: {
      type: Array as PropType<IDataItem[]>,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    more: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    checkedValue: {
      type: Array as PropType<IDataItem['value'][]>,
    },
    moreValue: {
      type: Boolean,
    },
    keywordValue: {
      type: String,
    },
  },

  setup(props, ctx) {
    const { data } = toRefs(props);
    const pCheckedValue = useVModel(props, 'checkedValue', ctx.emit);
    const pMoreValue = useVModel(props, 'moreValue', ctx.emit);
    const pKeywordValue = useVModel(props, 'keywordValue', ctx.emit);
    const flatedValues = computed(() => {
      const d = unref(data);
      if (!d) return null;
      return flattenValues(d);
    });

    const handleMoreClick = () => {
      pMoreValue.value = !pMoreValue.value;
    };

    const handleAllClick = () => {
      const _flatedValues = unref(flatedValues) ?? [];
      const _pCheckedValue = unref(pCheckedValue);

      // eslint-disable-next-line no-unused-expressions
      pCheckedValue.value = [
        ..._pCheckedValue ?? [],
        ...arrayDifference(_flatedValues, _pCheckedValue ?? []),
      ];
    };

    const handleClearClick = () => {
      const _flatedValues = unref(flatedValues) ?? [];
      const _pCheckedValue = unref(pCheckedValue);

      const intersection = arrayIntersection(_flatedValues, _pCheckedValue ?? []);
      const difference = arrayDifference(_pCheckedValue ?? [], intersection);

      pCheckedValue.value = difference;
    };

    const visibleList = computed(() => {
      if (Array.isArray(props.data) && props.data.length > 0) return true;
      if (props.loading) return false;
      return false;
    });

    return {
      visibleList,
      pCheckedValue,
      pMoreValue,
      pKeywordValue,
      handleMoreClick,
      handleAllClick,
      handleClearClick,
    };
  },

  components: {
    Checkbox,
    CheckboxGroup,
    ChevronUpIcon,
    ChevronDownIcon,
    CollapseTransition,
  },
});
