/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-named-default */
import { getNextQuery } from '@/router/utils/query';
import { tranRouteToLocation } from '@/router/utils/utils';
import type { ComponentOptions, default as Vue } from 'vue';
import type { NavigationGuard } from 'vue-router';
import { addValue, includeValue } from './utils';

export interface ICreateEventFilterMixinProps {
  queryName: string;
  paramLocaleName: string
}

export const createEventFilterMixin = ({
  queryName,
  paramLocaleName,
}: ICreateEventFilterMixinProps): ComponentOptions<Vue> => {
  const guard: NavigationGuard = (to, from, next) => {
    const locale = to.params[paramLocaleName];
    const filter = to.query[queryName] as unknown as Record<string, string> ?? {};
    if (!locale || includeValue(filter, 'target_market', locale)) return next();

    locale && addValue(filter, 'target_market', locale);

    const nextQuery = getNextQuery(to, { [queryName]: filter });
    next({ ...tranRouteToLocation(to), query: nextQuery });
  };

  return {
    beforeRouteEnter(to, from, next) {
      guard(to, from, next);
    },
  };
};
