import _ from 'lodash';
import { onKeyStroke } from '@vueuse/core';
import { watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

export interface IUseControlledKeyStrokeProps {
  enable: Ref<boolean>
  key: Parameters<typeof onKeyStroke>[0],
  handler: Parameters<typeof onKeyStroke>[1],
  options?: Parameters<typeof onKeyStroke>[2],
}

export const useControlledKeyStroke = ({
  enable,
  key,
  handler,
  options,
}: IUseControlledKeyStrokeProps) => {
  let stop = _.noop;

  watch(enable, (val) => {
    stop();

    if (val) {
      stop = onKeyStroke(key, handler, options);
    } else {
      stop = _.noop;
    }
  }, { immediate: true });
};
