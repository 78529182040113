/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { computed, unref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import type { IEventFilterData } from './event-filter/utils';

export interface IUseEventFilterDataProps {
  formData: Ref<IEventFilterData>
}

export interface IUseEventFilterData {
  data: Ref<string[]>;
}

export const useEventFilterData = ({
  formData,
}: IUseEventFilterDataProps): IUseEventFilterData => {
  const data = computed<string[]>({
    get: () => {
      const _data: string[] = [];
      const _formData = unref(formData);

      Object.keys(_formData).forEach((key) => {
        _formData[key].forEach((key2) => {
          _data.push(`${key}.${key2}`);
        });
      });

      return _data;
    },
    set: (val) => {
      formData.value = val.reduce((obj, item) => {
        const [key, value] = item.split('.');
        obj[key] = [
          ...(obj[key] ?? []),
          value,
        ];
        return obj;
      }, {} as Record<string, string[]>);
    },
  });

  return {
    data,
  };
};
