import qs from 'qs';
import VueRouter from 'vue-router';
import Home from '@/pages/Home';
import type { VueConstructor } from 'vue';

export interface ICreateRouterProps {
  Vue: VueConstructor
}

export const createRouter = ({
  Vue,
}: ICreateRouterProps) => {
  Vue.use(VueRouter);

  const router = new VueRouter({
    mode: 'history',

    /**
     * [BUG] 使用 qs 想實現， ?filter[a]=aa,a1,a2 => { filter: { a: ['aa', 'a1', 'a2'] } }，但遇到了他套件上的 bug， https://github.com/ljharb/qs/issues/410
     * 所以只使用 qs 的 object nested parse，不使用 comma format，改成內部自己實作 parse
     */
    parseQuery: (query) => qs.parse(query, {
      ignoreQueryPrefix: true,
    }),

    stringifyQuery: (query) => qs.stringify(query, {
      addQueryPrefix: true,
    }),

    routes: [
      {
        path: '/:locale',
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: '',
            component: Home,
          },
        ],
      },
    ],
  });

  return {
    router,
  };
};
