

































































import { defineComponent, toRefs } from '@vue/composition-api';
import { usePagination } from '@/services/pagination';
import { useVModels } from '@vueuse/core';
import PageFirstIcon from '@/assets/icons/page-first.svg?inline';
import PageLastIcon from '@/assets/icons/page-last.svg?inline';
import PagePrevIcon from '@/assets/icons/page-prev.svg?inline';
import PageNextIcon from '@/assets/icons/page-next.svg?inline';

export default defineComponent({
  name: 'Pagination',

  props: {
    page: {
      type: Number,
      required: true,
    },
    totalSize: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
  },

  setup(props, ctx) {
    const { pageSize } = toRefs(props);
    const { totalSize, page } = useVModels(props, ctx.emit);

    const {
      totalPage,
      visibleNext, visiblePrev,
      toFirst, toLast,
      toPrev, toNext,
      itemFrom, itemTo,
    } = usePagination({ totalSize, pageSize, currentPage: page });

    return {
      totalPage,
      currentPage: page,
      visibleNext,
      visiblePrev,
      toFirst,
      toLast,
      toPrev,
      toNext,
      itemFrom,
      itemTo,
    };
  },

  components: {
    PageFirstIcon,
    PageLastIcon,
    PagePrevIcon,
    PageNextIcon,
  },
});
