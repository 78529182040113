














































import { computed, defineComponent, unref } from '@vue/composition-api';
import { useWindowScroll } from '@vueuse/core';
import MenuIcon from '@/assets/icons/menu.svg?inline';

export default defineComponent({
  name: 'Header',

  emits: [
    'click-hamburger',
  ],

  setup() {
    const { y } = useWindowScroll();
    const visiblePhysical = computed(() => unref(y) > 50);
    const urlWwwSite = window.SYNO_CONSTANTS.URL_WWW_SITE;

    return {
      visiblePhysical,
      urlWwwSite,
    };
  },

  components: {
    MenuIcon,
  },
});
