









import { defineComponent } from '@vue/composition-api';
import { useCssVarWindowSize } from './services/css-var-window-size';

export default defineComponent({
  name: 'App',

  setup() {
    useCssVarWindowSize();
    return {};
  },
});
