
















































































import { defineComponent, computed, unref } from '@vue/composition-api';
import { useI18n } from '@/i18n/helpers/i18n';

export default defineComponent({
  name: 'Footer',

  props: {
    language: {
      type: String,
    },
    visibleChinaIcp: {
      type: Boolean,
      default: false,
    },
    visibleLanguage: {
      type: Boolean,
      default: true,
    },
    visibleImprint: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'click-language',
    'click-cookie',
  ],

  setup: () => {
    const i18n = useI18n();
    const copyrightText = computed(() => {
      const year = window.SYNO_WEB.currentYear;
      const text = `${unref(i18n).t('footer.copyright')}`;
      return text.replace(':YEAR', `${year}`);
    });

    return {
      copyrightText,
    };
  },
});
