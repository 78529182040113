/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { computed, unref, watch } from '@vue/composition-api';
import type { Ref, ComputedRef } from '@vue/composition-api';

export interface IUsePaginationProps {
  currentPage: Ref<number>;
  totalSize: Ref<number>;
  pageSize: Ref<number>;
}

export interface IPagination {
  totalPage: ComputedRef<number>;
  visibleNext: ComputedRef<boolean>;
  visiblePrev: ComputedRef<boolean>;
  itemFrom: ComputedRef<number>;
  itemTo: ComputedRef<number>;
  toFirst: () => void;
  toLast: () => void;
  toPrev: () => void;
  toNext: () => void;
}

export const usePagination = ({
  currentPage,
  totalSize,
  pageSize,
}: IUsePaginationProps): IPagination => {
  type Self = IPagination;

  const totalPage = computed(() => Math.max(1, Math.ceil(unref(totalSize) / unref(pageSize))));

  const isCorrectCurrentPage = computed(() => unref(currentPage) <= unref(totalPage));

  const visibleNext = computed(() => {
    const _currentPage = unref(currentPage);
    const _totalPage = unref(totalPage);
    return unref(isCorrectCurrentPage)
      && !(_currentPage === _totalPage || _totalPage === 0);
  });

  const visiblePrev = computed(() => unref(isCorrectCurrentPage)
    && !(unref(currentPage) <= 1));

  const toFirst: Self['toFirst'] = () => {
    currentPage.value = 1;
  };

  const toLast: Self['toLast'] = () => {
    currentPage.value = totalPage.value;
  };

  const toPrev: Self['toPrev'] = () => {
    currentPage.value -= 1;
  };

  const toNext: Self['toNext'] = () => {
    currentPage.value += 1;
  };

  watch(totalPage, (val) => {
    const oldCurrentPage = currentPage.value;
    if (val > 0 && oldCurrentPage === 0) {
      currentPage.value = 1;
    } else if (oldCurrentPage > val) {
      currentPage.value = val === 0 ? 1 : val;
    }
  });

  const itemFrom = computed(() => {
    if (!unref(isCorrectCurrentPage)) return NaN;

    const _currentPage = unref(currentPage);
    const _totalSize = unref(totalSize);
    const _pageSize = unref(pageSize);
    if (_totalSize === 0) return 0;
    return (_currentPage - 1) * _pageSize + 1;
  });

  const itemTo = computed(() => {
    if (!unref(isCorrectCurrentPage)) return NaN;

    const _currentPage = unref(currentPage);
    const _totalSize = unref(totalSize);
    const _pageSize = unref(pageSize);
    return Math.min(_currentPage * _pageSize, _totalSize);
  });

  return {
    totalPage,
    visibleNext,
    visiblePrev,
    itemFrom,
    itemTo,
    toFirst,
    toLast,
    toPrev,
    toNext,
  };
};
