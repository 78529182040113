/* eslint-disable no-return-assign */
import _ from 'lodash';
import {
  unref, computed, customRef, watch,
} from '@vue/composition-api';
import { useRouteQuery } from '@/router/helpers/query';
import { getNormalizedParseValue, getNormalizedStringifyValue, IEventFilterData } from './utils';

export interface IUseListFilterWithRouteProps {
  queryName: string
}

export const useListFilterWithRoute = ({
  queryName,
}: IUseListFilterWithRouteProps) => {
  const query = useRouteQuery<Record<string, string>>(queryName, {}, { mode: 'replace' });

  let triggerValue = _.noop;
  let data: IEventFilterData = {};
  const value = customRef<IEventFilterData>((track, trigger) => {
    triggerValue = trigger;
    return {
      get: () => {
        track();
        return data;
      },
      set: (val) => {
        query.value = getNormalizedStringifyValue(val);
      },
    };
  });

  watch(query, (val, oldVal) => {
    if (_.isEqual(val, oldVal)) return;
    data = getNormalizedParseValue(query.value);
    triggerValue();
  }, { immediate: true });

  return {
    value,
  };
};
