var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.itemFrom)+" - "+_vm._s(_vm.itemTo)+" / "+_vm._s(_vm.totalSize)+" ")]),_c('div',{staticClass:"flex space-x-5 ml-7"},[_c('button',{staticClass:"disabled:cursor-not-allowed",attrs:{"disabled":!_vm.visiblePrev},on:{"click":_vm.toFirst}},[_c('PageFirstIcon',{staticClass:"fill-current",class:{
          'text-gray-300': !_vm.visiblePrev,
        }})],1),_c('button',{staticClass:"disabled:cursor-not-allowed",attrs:{"disabled":!_vm.visiblePrev},on:{"click":_vm.toPrev}},[_c('PagePrevIcon',{staticClass:"fill-current",class:{
          'text-gray-300': !_vm.visiblePrev,
        }})],1),_c('div',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.$t('event_portal.pageination_pages', { totalPage: _vm.totalPage, currentPage: _vm.currentPage }))+" ")]),_c('button',{staticClass:"disabled:cursor-not-allowed",attrs:{"disabled":!_vm.visibleNext},on:{"click":_vm.toNext}},[_c('PageNextIcon',{staticClass:"fill-current",class:{
          'text-gray-300': !_vm.visibleNext,
        }})],1),_c('button',{staticClass:"disabled:cursor-not-allowed",attrs:{"disabled":!_vm.visibleNext},on:{"click":_vm.toLast}},[_c('PageLastIcon',{staticClass:"fill-current",class:{
          'text-gray-300': !_vm.visibleNext,
        }})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }