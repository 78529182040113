/* eslint-disable no-underscore-dangle */
import { computed, ref } from '@vue/composition-api';
import { useMediaQuery } from '@vueuse/core';
import type { WritableComputedRef } from '@vue/composition-api';

export interface ISidebar {
  visible: WritableComputedRef<boolean>;
}

export const useSidebar = (): ISidebar => {
  const breakpoint = '(min-width: 1280px)';
  const isXLScreen = useMediaQuery(breakpoint);
  const innerVisible = ref(false);

  const visible = computed({
    get: () => {
      if (isXLScreen.value === true) return false;
      return innerVisible.value;
    },
    set: (val) => {
      innerVisible.value = val;
    },
  });

  return {
    visible,
  };
};
