/* eslint-disable import/prefer-default-export */
import VueRouter from 'vue-router';
import type { RawLocation } from 'vue-router';

// 真的不能用 import { isNavigationFailure, NavigationFailureType } from 'vue-router';
const { isNavigationFailure, NavigationFailureType } = VueRouter;

/**
 * 安全地執行 route.push, route.replace
 */
export const routeTo = (
  router: VueRouter,
  location: RawLocation,
  actions: 'push' | 'replace' = 'push',
): void => {
  router[actions](location).catch((error) => {
    if (isNavigationFailure(error, NavigationFailureType.redirected)) return;
    if (isNavigationFailure(error, NavigationFailureType.duplicated)) return;
    throw error;
  });
};
