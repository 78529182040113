/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import type { AxiosInstance } from 'axios';
import type { VueConstructor } from 'vue';
import type { RequestFactoryFn } from './helpers/request';

export interface IApiProps {
  Vue: VueConstructor;
}

export interface IApi {
  request: AxiosInstance;
  withRequest: <TData, TArgs extends any[]>(
    fn: RequestFactoryFn<TData, TArgs>
  ) => ReturnType<RequestFactoryFn<TData, TArgs>>;
}

export const createApi = ({
  Vue,
}: IApiProps): IApi => {
  type Self = IApi

  const request: Self['request'] = axios.create({
    baseURL: '/',
  });

  const withRequest: Self['withRequest'] = (fn) => fn({ request });

  const instance: IApi = {
    request,
    withRequest,
  };

  Vue.mixin({
    beforeCreate() {
      this.$api = instance;
    },
  });

  return instance;
};

declare module 'vue/types/vue' {
  interface Vue {
    $api: IApi;
  }
}
