/* eslint-disable import/prefer-default-export */
import { watchEffect } from '@vue/composition-api';
import { useCssVar, useWindowSize } from '@vueuse/core';

/**
 * 在 mobile 上的 browser 會有 header, footer 滾動時會顯示或隱藏，所以 vh 會有不一樣的高度
 * 但在 Safari 上的 vh 不會依照 header, footer 顯示時去更改
 * 參考此：https://dev.to/admitkard/mobile-issue-with-100vh-height-100-100vh-3-solutions-3nae
 */
export const useCssVarWindowSize = () => {
  const { height, width } = useWindowSize();
  const vh = useCssVar('--vh');
  const vw = useCssVar('--vw');

  watchEffect(() => {
    vh.value = `${height.value / 100}px`;
    vw.value = `${width.value / 100}px`;
  }, { flush: 'post' });
};
