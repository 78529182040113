

























/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import { defineComponent } from '@vue/composition-api';
import PlusCircelIcon from '@/assets/icons/plus-circel--solid.svg?inline';
import type { PropType } from '@vue/composition-api';

export interface IProps {
  data: {
    from: string;
    to: string;
    timezone: string;
  };
  visibleAddCalendar: boolean;
}

export default defineComponent({
  name: 'EventCardDate',

  props: {
    data: {
      type: Object as PropType<IProps['data']>,
      required: true,
    },
    visibleAddCalendar: {
      type: Boolean as PropType<IProps['visibleAddCalendar']>,
      default: false,
    },
  },

  emits: [
    'click-add-calendar',
  ],

  setup(props, ctx) {
    const handleAddCalendarClick = () => {
      ctx.emit('click-add-calendar');
    };

    return {
      handleAddCalendarClick,
    };
  },

  components: {
    PlusCircelIcon,
  },
});
