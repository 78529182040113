/* eslint-disable import/prefer-default-export */
import { readonly, ref } from '@vue/composition-api';

export const useLanguageSwitch = () => {
  const visible = ref(false);
  const config = readonly(window.SYNO_WEB.languageSwitcherConfig);

  return {
    visible,
    config,
  };
};
