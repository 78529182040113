/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import { unref, watchEffect } from '@vue/composition-api';
import { unrefElement } from '@vueuse/core';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import type { Ref } from '@vue/composition-api';

export interface IUseLockBodyScrollProps {
  target: Ref<HTMLElement | null>;
  enable: Ref<boolean>;
}

export const useLockBodyScroll = ({
  enable,
  target,
}: IUseLockBodyScrollProps): void => {
  watchEffect((onInvalidate) => {
    const _target = unrefElement(target);
    const _enable = unref(enable);
    if (_target) {
      if (_enable) {
        disableBodyScroll(_target);
      } else {
        enableBodyScroll(_target);
      }
    }
    onInvalidate(() => {
      clearAllBodyScrollLocks();
    });
  });
};
