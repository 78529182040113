/* eslint-disable no-unused-expressions */
import _ from 'lodash';
import { onClickOutside } from '@vueuse/core';
import { Ref, watch } from '@vue/composition-api';

export interface IUseControlledClickOutsideProps {
  enable: Ref<boolean>,
  target: Parameters<typeof onClickOutside>[0],
  handler: Parameters<typeof onClickOutside>[1],
  options?: Parameters<typeof onClickOutside>[2],
}

export const useControlledClickOutside = ({
  enable,
  target,
  handler,
  options,
}: IUseControlledClickOutsideProps) => {
  let stop = _.noop;

  watch(enable, (val) => {
    stop();

    if (val) {
      stop = onClickOutside(target, handler, options) ?? _.noop;
    } else {
      stop = _.noop;
    }
  }, { immediate: true });
};
