













































/* eslint-disable no-underscore-dangle */
import {
  computed, defineComponent, toRefs, unref,
} from '@vue/composition-api';
import { useVModel } from '@vueuse/core';
import EventCardItem from '@/components/EventCardItem.vue';
import Pagination from '@/components/Pagination.vue';
import type { PropType } from '@vue/composition-api';
import type { IEventCardItem } from '@/services/event-card-list';

export interface IProps {
  total: number
  page: number
  pageSize: number
  data: IEventCardItem[] | null
  loading: boolean
}

export default defineComponent({
  name: 'EventCardList',

  props: {
    page: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    data: {
      type: Array as PropType<IProps['data']>,
    },
    total: {
      type: Number,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'click-item-video',
  ],

  setup(props, ctx) {
    const { data } = toRefs(props);
    const pPage = useVModel(props, 'page', ctx.emit);

    const isEmptyData = computed(() => {
      const _data = unref(data);
      return !Array.isArray(_data) || _data.length <= 0;
    });

    return {
      pPage,
      isEmptyData,
    };
  },

  components: {
    EventCardItem,
    Pagination,
  },
});
