/* eslint-disable no-underscore-dangle */
import { ref, unref } from '@vue/composition-api';
import { useVueInstanceProperty } from '@/utils/vue-helper';
import type { RequestFactoryFn } from './request';

export const useApi = () => useVueInstanceProperty('$api');

export const useFetch = <TData, TArgs extends any[]>(fn: RequestFactoryFn<TData, TArgs>) => {
  const $api = useApi();

  const data = ref<TData | null>(null);
  const isFetching = ref(false);

  const exec: (...args: TArgs) => Promise<void> = async (...args) => {
    const _fn = unref($api).withRequest(fn);

    try {
      isFetching.value = true;
      const response = await _fn(...args);
      data.value = response as any;
    } finally {
      isFetching.value = false;
    }
  };

  return {
    exec,
    data,
    isFetching,
  };
};
