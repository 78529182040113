






















































































import {
  defineComponent, ref, watch, unref, computed,
} from '@vue/composition-api';
import { useEventFilterList } from '@/services/event-filter-list';
import { IEventCardItem, useEventCardList } from '@/services/event-card-list';
import { useListPageWithRoute } from '@/services/list-page/data-with-route';
import { useEventFilterData } from '@/services/event-filter-data';
import { createListPageMixin } from '@/services/list-page/mixins-with-route';
import { createEventFilterMixin } from '@/services/event-filter/mixins-with-route';
import { useSidebar } from '@/services/sidebar';
import { useLocale } from '@/i18n/helpers/locale';
import { useListFilterWithRoute } from '@/services/event-filter/data-with-route';
import { useLanguageSwitch } from '@/services/language-switch';
import Header from '@/components/Header.vue';
import FilterList from '@/components/FilterList.vue';
import EventCardList from '@/components/EventCardList.vue';
import MenuIcon from '@/assets/icons/menu.svg?inline';
import ModalVideo from '@/components/ModalVideo.vue';
import Sidebar from '@/components/Sidebar.vue';
import Footer from '@/components/Footer.vue';
import LanguageSwitch from '@/components/LanguageSwitch.vue';

export default defineComponent({
  name: 'HomePage',

  mixins: [
    createListPageMixin({
      queryName: 'page',
    }),
    createEventFilterMixin({
      queryName: 'filter',
      paramLocaleName: 'locale',
    }),
  ],

  setup() {
    const locale = useLocale();
    const { isChina, languageDisplay } = window.SYNO_WEB;

    const modalVideoSrc = ref<string | null>(null);
    const visibleModalVideo = ref(false);

    const isVisibleImprint = computed(() => unref(locale) === 'de-de');

    const {
      visible: visibleSidebar,
    } = useSidebar();

    const {
      value: page,
      size: pageSize,
    } = useListPageWithRoute({
      queryName: 'page',
    });

    const {
      value: filterFormData,
    } = useListFilterWithRoute({
      queryName: 'filter',
    });

    const {
      data: filterData,
    } = useEventFilterData({
      formData: filterFormData,
    });

    const {
      data: filters,
      isFetching: isFetchingFilters,
    } = useEventFilterList({
      language: locale,
      isChina,
    });

    const {
      data: cards,
      total: totalSize,
      isFetching: isFetchingCardList,
    } = useEventCardList({
      language: locale,
      filterFormData,
      page,
      pageSize,
    });

    const handleFilterListBackClick = () => {
      visibleSidebar.value = false;
    };

    const handleEventCardItemVideoClick = (data: IEventCardItem) => {
      modalVideoSrc.value = data.video!.link;
      visibleModalVideo.value = true;
    };

    watch(filterFormData, () => {
      page.value = 1;
    });

    watch(page, () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });

    const {
      visible: visibleLanguageSwitch,
      config: configLanguageSwitch,
    } = useLanguageSwitch();

    const handleFooterLanguageClick = () => {
      visibleLanguageSwitch.value = true;
    };
    const handleFooterCookieClick = () => {
      window.SYNO_COOKIE_ELEMENT.showDialog();
    };

    return {
      languageDisplay,
      isChina,
      isVisibleImprint,

      filterData,
      filters,
      isFetchingFilters,

      totalSize,
      pageSize,
      page,
      cards,
      isFetchingCardList,

      visibleSidebar,
      handleFilterListBackClick,

      modalVideoSrc,
      visibleModalVideo,
      handleEventCardItemVideoClick,

      visibleLanguageSwitch,
      configLanguageSwitch,
      handleFooterLanguageClick,
      handleFooterCookieClick,
    };
  },

  components: {
    FilterList,
    EventCardList,
    MenuIcon,
    Header,
    ModalVideo,
    Sidebar,
    Footer,
    LanguageSwitch,
  },
});
