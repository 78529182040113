
















































import { defineComponent } from '@vue/composition-api';
import { templateRef, useVModel } from '@vueuse/core';
import { useLockBodyScroll } from '@/services/lock-body-scroll';
import { useControlledKeyStroke } from '@/services/controlled-key-stroke';

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const sidebar = templateRef<HTMLElement | null>('sidebar', null);
    const pVisible = useVModel(props, 'visible', ctx.emit);

    useLockBodyScroll({
      enable: pVisible,
      target: sidebar,
    });

    useControlledKeyStroke({
      enable: pVisible,
      key: 'Escape',
      handler: (e) => {
        e.preventDefault();
        pVisible.value = false;
      },
    });

    return {
      pVisible,
    };
  },
});
