/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
import { noop } from 'lodash';
import { customRef, getCurrentInstance } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import type { Vue } from 'vue/types/vue';

export const useVueInstanceProperty = <T extends keyof Vue>(
  key: T,
): Ref<Vue[T]> => {
  const vm = getCurrentInstance();

  return customRef(() => ({
    get: () => vm?.proxy[key],
    set: noop,
  }));
};
