/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */

export interface IEventFilterData {
  [key: string]: string[];
}

export const createValueFromLanguage = (
  lang: string | undefined | null,
): string | null => {
  if (typeof lang !== 'string' || !lang) return null;
  return `target_market.${lang}`;
};

export const parseValue = (
  value: string,
): string[] => value.split(',').map((item) => item.trim()).filter((item) => !!item);

export const stringifyValue = (
  value: string[],
): string => value.map((item) => item.trim()).filter((item) => !!item).join(',');

export const addValue = (
  data: Record<string, string>,
  key: string,
  value: string,
): void => {
  const val = parseValue(data[key] ?? '');
  val.push(value);
  data[key] = stringifyValue(val);
};

export const includeValue = (
  data: Record<string, string>,
  key: string,
  value: string,
): boolean => {
  const val = parseValue(data[key] ?? '');
  return val.includes(value);
};

export const getNormalizedParseValue = (
  data: Record<string, string>,
): IEventFilterData => {
  const data2: IEventFilterData = {};
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      data2[key] = parseValue(data[key] ?? '');
    }
  }
  return data2;
};

export const getNormalizedStringifyValue = (
  data: IEventFilterData,
): Record<string, string> => {
  const data2: Record<string, string> = {};
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      data2[key] = stringifyValue(data[key]);
    }
  }
  return data2;
};
